<template>
  <div class="pagination-wrapper">
    <div
      :class="['record-count', { _active: isOptionsOpened }]"
      v-click-outside="() => (isOptionsOpened = false)"
      ref="count"
    >
      <div
        class="record-count__header"
        @click="calculateRecordPosition($event)"
      >
        <div class="record-count__value">
          <span class="value">
            {{ value && value.limit }}
          </span>
          <span class="text">{{ computedText }}</span>
          <i class="icon-angle-down icon"></i>
        </div>
      </div>
      <ul class="record-count__options" ref="options">
        <div class="triangle-with-shadow"></div>
        <li
          :class="[
            'record-count__option',
            { _selected: this.value && this.value.limit === o },
          ]"
          v-for="o of limitOptions"
          :key="o"
          :rel="o"
          @click="changeLimit(o)"
        >
          {{ o }}
        </li>
      </ul>
    </div>
    <ul class="pagination">
      <li class="pagination__item">
        <a @click.prevent="changePage(1)" href="#">
          <i class="icon-start"></i>
        </a>
      </li>
      <li class="pagination__item">
        <a @click.prevent="changePage(page - 1)" href="#">
          <i class="icon-prev"></i>
        </a>
      </li>
      <li class="pagination__item" v-for="p of pages" :key="p == null ? -1 : p">
        <a
          :class="{ _active: p === this.page }"
          href="#"
          @click.prevent="changePage(p)"
          >{{ p == null ? "..." : p }}</a
        >
      </li>
      <li class="pagination__item">
        <a @click.prevent="changePage(page + 1)" href="#">
          <i class="icon-next"></i>
        </a>
      </li>
      <li class="pagination__item">
        <a @click.prevent="changePage(count)" href="#">
          <i class="icon-end"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { clamp, range } from "lodash";
export default {
  props: {
    value: {
      type: Object,
    },
    totalRows: {
      type: Number,
    },
    limitOptions: {
      type: Array,
      default: () => [5, 10, 25, 50, 100],
    },
    textForms: {
      type: Array,
    },
  },
  data() {
    return {
      isOptionsOpened: false,
    };
  },
  computed: {
    computedText() {
      return this.declOfNum(this.value, this.textForms);
    },
    page() {
      return this.value ? this.value.offset / this.value.limit + 1 : 1;
    },
    count() {
      return this.value ? Math.ceil(this.totalRows / this.value.limit) : 1;
    },
    pages() {
      if (!this.value || !this.totalRows) {
        return [1];
      }
      const from = clamp(this.page - 5, 1, this.count);
      const to = clamp(from + 9, 1, this.count);
      const before = from > 1 ? [null] : [];
      const after = to < this.count ? [null] : [];
      return [...before, ...range(from, to + 1), ...after];
    },
  },
  created() {
    if (!this.value) {
      this.setDefaultValue();
    }
  },
  watch: {
    value(newValue) {
      this.isOptionsOpened = false;
      if (!newValue) {
        this.setDefaultValue();
      }
    },
  },
  methods: {
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) {
        return text_forms[2];
      }
      if (n1 > 1 && n1 < 5) {
        return text_forms[1];
      }
      if (n1 === 1) {
        return text_forms[0];
      }
      return text_forms[2];
    },
    setDefaultValue() {
      this.$emit("update:value", { limit: 10, offset: 0 });
    },
    changePage(p) {
      if (p == null || p < 1 || p > this.count) {
        return;
      }
      this.$emit("update:value", {
        limit: this.value.limit,
        offset: this.value.limit * (p - 1),
      });
    },
    changeLimit(l) {
      this.$emit("update:value", { limit: l, offset: 0 });
      this.isOptionsOpened = false;
    },
    calculateRecordPosition(e) {
      const { count } = this.$refs;

      if (!count.classList.contains("_active")) {
        const target = e.target;
        const options = target.nextSibling;
        const optionsHeight = options.offsetHeight;
        const optionsWidth = options.offsetWidth;
        const windowHeight = window.innerHeight;
        const triangle = options.querySelector(".triangle-with-shadow");
        const itemOffsetTop =
          target.getBoundingClientRect().top + window.pageYOffset;
        const itemOffsetLeft =
          target.getBoundingClientRect().left + window.pageXOffset;
        const containerHeight = target.offsetHeight;
        const containerWidth = target.offsetWidth;
        let X = options.getBoundingClientRect().left + window.pageXOffset;
        let Y = options.getBoundingClientRect().top + window.pageYOffset;

        let top = itemOffsetTop + containerHeight + 12 + "px";
        let left = X + itemOffsetLeft + containerWidth - optionsWidth;

        if (itemOffsetTop + optionsHeight + Y > windowHeight) {
          top = itemOffsetTop - optionsHeight - 12 - window.pageYOffset + "px";

          triangle.style.top = "auto";
          triangle.style.bottom = -30 + "px";
          triangle.style.transform = "rotate(180deg) translateX(50%)";
        }

        options.style.top = top;
        options.style.left = left;

        this.isOptionsOpened = true;
      } else {
        this.isOptionsOpened = false;
      }
    },
  },
  mounted() {
    const { count, options } = this.$refs;

    window.addEventListener("scroll", () => {
      if (count.classList.contains("_active")) {
        count.classList.remove("_active");
        options.removeAttribute("style");
        this.isOptionsOpened = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.pagination-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
  }

  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    a {
      display: block;
      background: #fff;
      border: 1px solid #dfdfff;
      font-weight: 500;
      text-align: center;

      @media screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }

      @media screen and (min-width: 768px) {
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
      }

      &:hover,
      &._active {
        background: #d8e5ff;
      }
    }
  }
}

.record-count {
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    user-select: none;
  }

  &__value {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    pointer-events: none;

    .text {
      margin-left: 5px;
    }
  }

  &._active {
    .icon {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    opacity: 0;
    background: #ffffff;
    padding: 20px 15px;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(128, 141, 162, 0.36);
    position: fixed;
    top: calc(100% + 5px);
    z-index: -999;

    .triangle-with-shadow {
      user-select: none;
      pointer-events: none;
      width: 30px;
      height: 30px;
      overflow: hidden;
      position: absolute;
      top: -30px;
      box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
      left: 50%;
      transform: translateX(-50%);

      &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #ffffff;
        transform: rotate(45deg);
        top: 22px;
        left: 7px;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__option {
    padding: 3px 0;

    &._selected {
      font-weight: 500;
      color: $color-blue-light;
    }
  }

  &._active {
    .record-count__options {
      opacity: 1;
      z-index: 99;
    }
  }
}
</style>
